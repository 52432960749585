/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/goodsManagement/number";

const state = {
  numberList:[],
  numberTotal: 0,
};

// actions
const actions = {
 // 列表
  async getNumber({ commit, state }, params) {
    let res = await api.postNumber(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  // 编辑
  async adminCardInfoEdit({ commit, state }, params) {
    return await api.adminCardInfoEdit(params);
  },
  // 删除
  async adminCardInfoDelete({ commit, state }, params) {
    return await api.adminCardInfoDelete(params);
  },
  // 批量导入
  async getNumberImport({ commit, state }, params) {
    return await api.postNumberImport(params);
  },
  // 批量导入
  async numberGetNumbers({ commit, state }, params) {
    return await api.numberGetNumbers(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.numberList = list;
    state.numberTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
