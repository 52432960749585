/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询 */
export const userNetList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/list",
    method: "post",
    data: params
  });
};

/** 添加 */
export const userNetAdd = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/add",
    method: "post",
    data: params
  });
};

/** 详情 */
export const userNetLoad = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/load",
    method: "post",
    data: params
  });
};

/** 删除 */
export const userNetDelete = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/delUser",
    method: "post",
    data: params
  });
};

/** 编辑 */
export const userNetEdit = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/edit",
    method: "post",
    data: params
  });
};

/** 重置密码 */
export const userNetResetPwd = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/resetPwd",
    method: "post",
    data: params
  });
};

/** 重置公众号登录密码 */
export const userNetResetMpPwd = function(params) {
  return axios({
    url: BaseUrl + "members/app/noLogin/resetMpPwd",
    method: "post",
    data: params
  });
};

/** 开通套餐 */
export const userNetOpen = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/open",
    method: "post",
    data: params
  });
};

/** 复通套餐 */
export const userNetRestore = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/restore",
    method: "post",
    data: params
  });
};


/** 报停 */
export const userNetStop = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/stop",
    method: "post",
    data: params
  });
};

/** 查询用户所在学校的宽带套餐 */
export const userNetMealSelect = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/mealSelect",
    method: "post",
    data: params
  });
};

/** 查询用户套餐的操作记录 */
export const userNetHistory = function(params) {
  return axios({
    url: BaseUrl + "net/admin/order/getList",
    method: "post",
    data: params
  });
};

export default {
  userNetList,
  userNetAdd,
  userNetDelete,
  userNetEdit,
  userNetLoad,
  userNetResetPwd,
  userNetResetMpPwd,
  userNetOpen,
  userNetMealSelect,
  userNetRestore,
  userNetStop,
  userNetHistory
};
