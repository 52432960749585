/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/user/openAccount";

const state = {
  storeList: [],
  storeTotal: 0,
};

// actions
const actions = {
  async batchOpenList({ commit, state }, params) {
    let result = await api.batchOpenList(params);
    commit("saveDeptList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
};

//getters
const getters = {};

const mutations = {
  saveDeptList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
