<template>
  <div class="page page-system-home">
    <div class="page-main page">
      <SideBar v-if="shopId" :subNav="subNav" :defaultOpened="opened"/>
      <div class="page-header cfx">
        <div class="userinfo">
          <div class="user">
            <el-avatar class="user-pic" :size="36" :src="homeUserDetails.headImg?homeUserDetails.headImg+'?x-oss-process=image/resize,m_fill,h_150,w_150':require('@/assets/images/p80x80.jpg')"></el-avatar>
          </div>
          <el-dropdown trigger="click">
            <span  class="el-dropdown-link userinfo-inner btn" style="color:#fff">
              {{ systemUser }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleModify()">修改密码</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <i @click="loginOut()" class="el-icon-switch-button"></i>
        </div>
        <div class="logo cfx">
          <div class="pic">
            <img src="../assets/images/logo.png" width="60">
          </div>
          <div class="txt">{{url?'菁星校园通信大厅':'菁星校园网络大厅'  }}</div>
        </div>
      </div>
      <div class="page-container page" :class="{ on: isSidebarCollapsed}">
        <div class="breadcrumb-container" id="breadcrumb-container">
          <span class="right-icons">
            <i class="el-icon-refresh right-icon" @click="refresh"></i>
          </span>
          <el-breadcrumb separator="/" class="breadcrumb-inner">
            <el-breadcrumb-item>
              {{ curBreadcrumbName }}
            </el-breadcrumb-item>
          </el-breadcrumb>
          <!-- 派单消息提示 -->
          <div class="fr hint" v-if="show&& number>0" @click="hint">
            <p>
              您有<span>{{number?number:0}}</span>条派单信息待处理
            </p>
            <img class="hintdel fr" @click="show=false" src="../assets/images/hintdel.svg" alt="">
          </div>
        </div>
        <div class="content-wrapper page" id="content-wrapper">
          <keep-alive :include="includedComponents">
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="bVisible" width="400px" :close-on-click-modal="false" :modal-append-to-body="false">
      <el-form label-width="80px" ref="psdFormFileds" :model="psdForm" :rules="psdRules" size="small">
        <el-form-item label="旧密码" prop="oldPasswd">
          <el-input clearable placeholder="请输入密码" v-model="psdForm.oldPasswd" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPasswd">
          <el-input clearable placeholder="请输入新密码" v-model="psdForm.newPasswd" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input clearable placeholder="请输入确认密码" v-model="psdForm.confirmPassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="bVisible = false">取消</el-button>
        <el-button size="small" type="success" :loading="bLoading" @click="submitModifyPsd">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapActions, mapMutations } from "vuex";
import DropdownMenu from "@/components/common/DropdownMenu.vue";
import SideBar from "@/components/common/SideBar.vue";
import { log, removeToken } from "@/common/utils";

export default {
  name: "home",
  components: {
    DropdownMenu,
    SideBar
  },
  data() {
    return {
      url:null,
      show:true,
      number:null,
      url:'',
      currentTabsName: "/dashboard",
      includedComponents: ["dashboard"],
      bVisible: false,
      bLoading: false,
      systemUser:'admin',
      homeUserDetails: {},
      psdForm:{
        oldPasswd:"",
        newPasswd:"",
        confirmPassword:"",
      },
      
      menus:[],
      subNav:[],
      curTitle: '',
      opened:'',
      shopId:null,
      // 提示派单类型
      hallType:1,   // 1 网络大厅  2 通信大厅
    };
  },
  computed: {
    ...mapState(["error", "tabsList", "currentTabPath", "curBreadcrumbName", "isSidebarCollapsed"]),
    psdRules() {
      let validatePassword = (rule, value, callback) => {
        if(!!this.psdForm.confirmPassword){
          if (this.psdForm.newPasswd !== this.psdForm.confirmPassword) {
            callback(new Error('两次输入密码不相同'));
          }
          callback();
        }
      };
      return {
        oldPasswd:[{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPasswd: [
          { required: true, message: "请输入新密码" },
          { min: 6, max: 12, message: "请输入6-12位数字字母组合", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "请再次输入新密码" },
          { validator: validatePassword, trigger: "blur" }
        ]
      };
    }
  },
  watch: {
    $route: "fetchData",
    error(val) {
      if(val.data.code == 501){
        removeToken()
        this.$router.push({ path: '/login' })
        this.$message.error('token过期,请重新登录')
        return
      }
      this.$message.error(val.data.message)
    },
    tabsList(tabs) {
      let arr = tabs.map((item) => item.templateName);
      this.includedComponents = Array.from(new Set(arr));
    },
  },
  
  methods: {
    ...mapMutations(["setTabsList", "setCurrentTab", "switchToTab", "setProvince"]),
    ...mapActions("systemDict",[ "commonDictGetDictFPid" ]),
    ...mapActions("account", ["membersUserPassword","getUserInfo"]),
    ...mapActions("systemMenu", ["getUserPermission"]),
    ...mapActions("fuseOrder",["getPaiNum"]),
    removeTab(fullPath) {
      let tabs = this.tabsList;
      let activeName = this.currentTabsName;
      if (activeName === fullPath) {
        tabs.forEach((tab, index) => {
          if (tab.fullPath === fullPath) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.fullPath;
              this.$router.push(activeName);
            }
          }
        });
      }
      this.currentTabsName = activeName;
      tabs = tabs.filter((tab) => tab.fullPath !== fullPath);
      this.setTabsList(tabs);
    },
    fetchData() {
      let tabs = this.tabsList;
      let name = this.$route.name;
      let path = this.$route.path;
      let fullPath = this.$route.fullPath;
      let resolved = this.$router.resolve(this.$route.path);
      let templateName = resolved.route.matched[resolved.route.matched.length-1].components.default.name;
      if (path == "/reload") {
        this.$router.go(-1);
        return;
      }
      this.currentTabsName = fullPath;
      //判断是否满足下钻
      if (tabs.some((item) => item.path == path && item.fullPath != fullPath)) {
        tabs.forEach((k) => {
          if (k.name == name) {
            k.name = name;
            k.fullPath = fullPath;
          }
        });
      } else if (!tabs.some((item) => item.fullPath == fullPath)) {
        tabs.push({
          name: name,
          path: path,
          fullPath: fullPath,
          templateName: templateName
        });
      }
      this.setTabsList(tabs);
      this.setCurrentTab({ context: this, fullPath });
    },
    handleClick(tab) {
      this.switchToTab({ context: this, tabIndex: tab.index });
    },
    refresh() {
      let resolved = this.$router.resolve(this.$route.path);
      let templateName = resolved.route.matched[1].components.default.name;
      this.includedComponents = this.includedComponents.filter(
        (item) => item !== templateName
      );
      this.$router.push("/reload");
    },
    // 关闭标签页
    closeTabs(type) {
      switch (type) {
        case 1:
          if (this.currentTabsName == "/dashboard") {
            this.$message({ message: "首页不可关闭", type: "warning" });
            return;
          }
          this.removeTab(this.currentTabsName);
          break;
        case 2: {
          let tabsList = this.tabsList.filter(
            (item) =>
              item.fullPath == this.currentTabsName ||
              item.path == "/dashboard"
          );
          this.setTabsList(tabsList);
          break;
        }
        case 3: {
          let tabsList = this.tabsList.filter(
            (item) => item.path == "/dashboard"
          );
          this.currentTabsName = "/dashboard";
          this.$router.push("/dashboard");
          this.setTabsList(tabsList);
          break;
        }
      }
    },
    // 关闭消息提示
    hint() {
      // this.$router.push("/fuseOrder")
    },

    handleModify(){
      this.bVisible = true
      this.$nextTick(() => {
        this.$refs.psdFormFileds.resetFields();
      })
    },
    submitModifyPsd(){
      this.$refs.psdFormFileds.validate(async (valid) => {
        if (valid) {
          this.bLoading = true
          try {
            await this.$confirm('确认提交修改密码吗？')
            let params = Object.assign({}, this.psdForm)
            await this.membersUserPassword(params)
            this.$message.success('密码修改成功,请重新登录')
            removeToken()
            this.$router.push('/login')
          }catch(e){
            log(e)
          }finally {
            this.bLoading = false
          }
        }
      })
    },
    async loginOut(){
      try {
        await this.$confirm("确定退出登录吗？");
        removeToken()
        this.$router.replace("/login");
      } catch (e) {
        log(e);
        if (e !== "cancel") {
          this.$router.replace("/login");
        }
      }
    },
  },
  async created(){
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
    if(this.url != -1) this.hallType = 2
    let province = await this.commonDictGetDictFPid({pid: 101001})
    this.setProvince(province)
  },
  
  async mounted() {
    // 派单消息提示
    this.number = await this.getPaiNum({hallType:this.hallType})

    const res = await this.getUserInfo()

    this.shopId = res.shopId
    console.log(res)
    this.homeUserDetails = res
    this.systemUser = res.extend.passport
    sessionStorage.setItem('userId', res.id)
    sessionStorage.setItem('shopId', res.shopId)
    sessionStorage.setItem('operator', res.operator)
    sessionStorage.setItem('schoolId', res.schoolId)
    sessionStorage.setItem('isAdmin', res.isAdmin)
    let route = this.$route;
    if (
      !this.tabsList.filter((item) => item.fullPath == route.fullPath).length
    ) {
      let resolved = this.$router.resolve(route.path);
      let templateName = resolved.route.matched[1].components.default.name;
      this.currentTabsName = route.fullPath;
      this.tabsList.push({
        name: route.name,
        path: route.path,
        fullPath: route.fullPath,
        templateName: templateName
      });
      this.setTabsList(this.tabsList);
      this.setCurrentTab({
        context: this,
        name: route.name,
        fullPath: route.fullPath
      });
    }
    let permission  = await this.getUserPermission({saas:res.shopId})
    this.$store.commit('setOA', permission)
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033') 
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
.page-system-home {
  .page-main {
    overflow: hidden;
    transition: left 0.3s ease-in-out;
    background: rgba(244, 244, 244, 1);
    .page-header {
      height: 60px;
      background: #28b892;
      box-shadow: -1px -1px 5px #057b4b inset;
      .userinfo { float:right; text-align: right; padding-right: 20px; height: 60px; line-height:60px; color: #fff; font-size:1.2em;
        .user { display:inline-block; line-height: 35px; }
        span { display:inline-block; color: #fff; margin: 0 10px; vertical-align: top; line-height:60px; font-size:1.2em; cursor: pointer; }
        .el-icon-switch-button { cursor: pointer; vertical-align: middle; margin: 0 10px; }
      }
      .logo { position: relative; width:50%;
        .pic { position: absolute; left:15px; top:50%; transform: translateY(-50%); }
        .txt { margin-left:80px; line-height:60px; font-size:30px; font-family: '黑体'; color:#fff; letter-spacing: 3px; }
      }
    }
    .page-container {
      top: 70px;
      right: 10px;
      left: 190px;
      bottom: 10px;
      .breadcrumb-container {
        .hint { width:250px;height:30px;background:#FF9240;border-radius: 15px;line-height: 30px;padding:0 20px; display: inline-block; color: #ffffff;
          .hintdel  { display: inline-block; line-height: 100px; width: 15px; height: 15px;overflow: hidden; margin-top: 8px;}
          p { display: inline-block; }
        }
        line-height: 30px;
        i {
          color: #00a199;
          font-size: 1.4em;
        }
        .right-icons {
          cursor: pointer;
          float: right;
          margin-right: 10px;
        }
        .right-icon {
          margin-left: 8px;
        }
        .el-icon-caret-left,
        .el-icon-caret-right {
          font-size: 20px;
          cursor: pointer;
        }
        .breadcrumb-inner {
          display: inline-block;
          color: #475669;
          padding: 0 20px 0 8px;
          border-left: 2px solid #00a199;
        }
      }
      .content-wrapper {
        top: 35px;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
      &.on {
        left: 79px;
      }
    }
  }
}
</style>
