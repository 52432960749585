/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询 */
export const userNetList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/userNet/getLikeList",
    method: "post",
    data: params
  });
};

 // 宽带列表
 export const mealList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/netMeal/selectAll",
    method: "post",
    data: params
  });
};

export default {
  userNetList, 
  mealList
};
