/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const listApply = function(params) {
  return axios({
    url: BaseUrl + "net/et/apply/list",
    method: "post",
    data: params
  });
};
 

// 编辑
export const editApply = function(params) {
  return axios({
    url: BaseUrl + "net/et/apply/edit",
    method: "post",
    data: params
  });
};



export default {
  listApply,
  editApply
};