/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const postCardList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardMeal/list",
    method: "post",
    data: params
  });
};
 // 所有学校
 export const postSchoolAll = function(params) {
  return axios({
    url: BaseUrl + "members/school/getUserSchools",
    method: "post",
    data: params
  });
};
 // 所有校区
 export const postSchoolDicts = function(params) {
  return axios({
    url: BaseUrl + "members/school/getUserSchoolDicts",
    method: "post",
    data: params
  });
};
// 批量上架/下架
export const postBatchStatusChange = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardMeal/batchStatusChange",
    method: "post",
    data: params
  });
};
// 详情
export const postCardLoad = function(params) {
    return axios({
      url: BaseUrl + "net/admin/cardMeal/load",
      method: "post",
      data: params
    });
  };
// 添加
export const postCardAdd = function(params) {
    return axios({
      url: BaseUrl + "net/admin/cardMeal/add",
      method: "post",
      data: params
    });
  };
// 修改
export const postCardEdit = function(params) {
    return axios({
      url: BaseUrl + "net/admin/cardMeal/edit",
      method: "post",
      data: params
    });
  };
// 删除
export const postCardDelete = function(params) {
    return axios({
      url: BaseUrl + "net/admin/cardMeal/delete",
      method: "post",
      data: params
    });
  };
export const goodsSelectAll = function(params) {
  return axios({
    url: BaseUrl + "net/admin/netMeal/selectAll",
    method: "post",
    data: params
  });
};



// 添加套餐分销
export const addShopPromotion = function (params) {
  return axios({
    url: BaseUrl + 'net/admin/netMeal/spread',
    method: 'post',
    data: params
  })
} 

export default {
  postCardList,
  postSchoolAll,
  postSchoolDicts,
  postCardLoad,
  postCardAdd,
  postCardEdit,
  postCardDelete,
  postBatchStatusChange,
  goodsSelectAll,
  addShopPromotion
};