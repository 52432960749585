/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表


export const byrhGetList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/bbxy/rh/getList",
    method: "post",
    data: params
  });
};

export default {
  byrhGetList
};