/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const postFuseOrder = function(params) {
  return axios({
    url: BaseUrl + "net/admin/order/getPageList",
    method: "post",
    data: params
  });
};
 // 详情
export const postFuseOrderLoad = function(params) {
  return axios({
    url: BaseUrl + "net/admin/order/load",
    method: "post",
    data: params
  });
};

// 详情
export const cardOrderAssign = function(params) {
  return axios({
    url: BaseUrl + "net/admin/order/assign",
    method: "post",
    data: params
  });
};

// 获取管理
export const getAppRoleUsers = function(params) {
  return axios({
    url: BaseUrl + "members/app/getShopTalent",
    method: "post",
    data: params
  });
};

// 派单数量查询
export const getPaiNum = function (params) {
  return axios({
    url: BaseUrl + 'net/admin/order/getPaiNum',
    method: 'post',
    data: params
  })
}

export default {
  postFuseOrder,
  postFuseOrderLoad,
  cardOrderAssign,
  getAppRoleUsers,
  getPaiNum
};