/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/financialList/bydk";

const state = {
  bydkList:[],
  bydkTotal: 0,
};

// actions
const actions = {
 // 列表
  async bydkGetList({ commit, state }, params) {
    let res = await api.bydkGetList(params);
    commit("bydkList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
};

//getters
const getters = {};

const mutations = {
  bydkList(state, { list, total }) {
    state.bydkList = list;
    state.bydkTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
