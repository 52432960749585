/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const postGoodsLableList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/label/list",
    method: "post",
    data: params
  });
};
 // 标签下拉选项
export const postGoodsLableAll = function(params) {
  return axios({
    url: BaseUrl + "net/admin/label/all",
    method: "post",
    data: params
  });
};
// 新增
export const postGoodsLableAdd = function(params) {
  return axios({
    url: BaseUrl + "net/admin/label/add",
    method: "post",
    data: params
  });
};
// 编辑
export const postGoodsLableEdit = function(params) {
  return axios({
    url: BaseUrl + "net/admin/label/edit",
    method: "post",
    data: params
  });
};
// 删除
export const postGoodsLableDelete = function(params) {
  return axios({
    url: BaseUrl + "net/admin/label/delete",
    method: "post",
    data: params
  });
};
export default {
  postGoodsLableList,
  postGoodsLableAll,
  postGoodsLableAdd,
  postGoodsLableEdit,
  postGoodsLableDelete
};