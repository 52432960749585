/* eslint-disable no-unused-vars */
import axios from "axios";
import { log, getAccessToken } from "@/common/utils";
import store from "@/store";
const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = (request) => {
  let accessToken = getAccessToken();
  if (accessToken) {
    request.headers["Authorization"] = accessToken;
  }
  if (request.data) {
    // 判断net appId为2
    // 判断sim appId为5
    request.data.appId = window.location.href.includes('sim.')|| window.location.href.includes('20033') ? 5 : 2
    // if (sessionStorage.getItem('shopId') !== 'undefined' ) {
    //   request.data.saas = sessionStorage.getItem('shopId')
    // }
  }
  return request;
};

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    let commit = store.commit;
    let type, code, data, message;
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        type = 'token';
        code = error.response.status;
        data = error.response.data;
        message = error.response.statusText;
      } else {
        type = 'server';
        code = error.response.status;
        data = error.response.data;
        message = error.response.statusText;
        if ((!message || message === "") && data) {
          message = data.message;
        }
      }
    } else if (error.request) {
      type = 'network'
      code = -1;
      data = error.request;
      message = "网络错误";
    } else {
      type = 'unknown'
      code = -1000;
      data = null;
      message = error.message;
    }

    commit("setError",
      {
        type,
        data: {
          code,
          data,
          message
        }
      },
      { root: true }
    );
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    if (response) {
      let data = response.data;
      let commit = store.commit;
      if (data.code != "0") {
        commit(
          "setError",
          {
            type: 'business',
            data
          },
          { root: true }
        );
        return Promise.reject({ ...data });
      } else {
        return data.data;
      }
    } else {
      return Promise.reject({ ...response });
    }
  }
  return response;
};

export default {
  init() {
    axios.interceptors.request.use((request) => requestHandler(request));
    axios.interceptors.response.use(
      (response) => successHandler(response),
      (error) => errorHandler(error)
    );
  }
};
