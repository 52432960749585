/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
/** 查询学校 */
export const getSchoolCombo = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolCombo",
    method: "post",
    data: params
  });
};

export const contestMatchSelectAll = function(params) {
  return axios({
    url: BaseUrl + "match/admin/contest/match/selectAll",
    method: "post",
    data: params
  });
};

export const contestBannerAll = function(params) {
  return axios({
    url: BaseUrl + "match/admin/contest/banner/all",
    method: "post",
    data: params
  });
};

/** 查找指定app角色的所有用户 */
export const getAppRoleUsers = function(params) {
  return axios({
    url: BaseUrl + "members/app/getAppRoleUsers",
    method: "get",
    params: params
  });
};

/** 获取学校筛选树 */
export const getSchoolTree = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolTree",
    method: "post",
    data: params
  });
};

export const commonDictGetDictFPid = function(params) {
  return axios({
    url: BaseUrl + "common/dict/getDictFPid",
    method: "post",
    data: params
  });
};

/** 发送短信 */
export const dbMobiles = function(params) {
  return axios({
    url: BaseUrl + `common/sms/noLogin/dbMobiles?msg=${params.msg}&phone=${params.phone}`,
    method: "get"
  });
};

export default {
  getSchoolCombo,
  contestMatchSelectAll,
  contestBannerAll,
  getAppRoleUsers,
  getSchoolTree,
  commonDictGetDictFPid,
  dbMobiles
};
