/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const netAdminSchoolNetList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/schoolNet/list",
    method: "post",
    data: params
  });
};

export const netAdminSchoolNetAll = function(params) {
  return axios({
    url: BaseUrl + "net/admin/schoolNet/all",
    method: "post",
    data: params
  });
};

export const adminSchoolNetAdd = function(params) {
  return axios({
    url: BaseUrl + "net/admin/schoolNet/add",
    method: "post",
    data: params
  });
};

export const adminSchoolNetLoad = function(params) {
  return axios({
    url: BaseUrl + "net/admin/schoolNet/load",
    method: "post",
    data: params
  });
};

export const adminSchoolNetEdit = function(params) {
  return axios({
    url: BaseUrl + "net/admin/schoolNet/edit",
    method: "post",
    data: params
  });
};

export const adminSchoolNetDelete = function(params) {
  return axios({
    url: BaseUrl + "net/admin/schoolNet/delete",
    method: "post",
    data: params
  });
};

// 查询学校(学校下拉选)
export const membersSchoolSelectAll = function(params) {
  return axios({
    url: BaseUrl + "members/school/selectAll",
    method: "post",
    data: params
  });
};

export default {
  netAdminSchoolNetList,
  netAdminSchoolNetAll,
  adminSchoolNetAdd,
  adminSchoolNetLoad,
  adminSchoolNetEdit,
  adminSchoolNetDelete,
  membersSchoolSelectAll,
};
