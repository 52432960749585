/* eslint-disable no-unused-vars */
const SchoolManagement = () => import ('@/components/basic/SchoolManagement')

export default {
  route:[
    {
      path: "schoolManagement",
      name: "学校管理",
      component: SchoolManagement
    }
  ]
};
