/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表

export const bydkGetList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/bbxy/dk/getList",
    method: "post",
    data: params
  });
};


export default {
  bydkGetList,
};