/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/store/info";

const state = {
  storeList: [],
  storeTotal: 0,
};

// actions
const actions = {
  // async batchRestoreList({ commit, state }, params) {
  //   let result = await api.batchRestoreList(params);
  //   commit("saveDeptList", {
  //     list: result.list,
  //     total: result.total,
  //   });
  //   return result;
  // },
  async storeInfoLoad({ commit, state }, params) {
    return await api.storeInfoLoad(params);
  },
  async storeInfoEdit({ commit, state }, params) {
    return await api.storeInfoEdit(params);
  },
  async getSchoolAreaTree({ commit, state }, params) {
    return await api.getSchoolAreaTree(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveDeptList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
