/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/goodsManagement/broadBand";

const state = {
  broadBandList:[],
  broadBandTotal: 0,
};

// actions
const actions = {
 // 列表
  async getBroadBand({ commit, state }, params) {
    let res = await api.postBroadBand(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  // 条件查找
  async getBroadBandSelect({ commit, state }, params) {
    return await api.postBroadBandSelect(params);
  },
   // 批量上架/下架
   async getBatchStatusChange({ commit, state }, params) {
    return await api.postBatchStatusChange(params);
  },
  // 获取所有学校
  async getSchoolAll({ commit, state }, params) {
    return await api.postSchoolAll(params);
  },
  // 获取所有校区
  async getSchoolDicts({ commit, state }, params) {
    return await api.postSchoolDicts(params);
  },
  // 添加
  async getBroadBandAdd({ commit, state }, params) {
    return await api.postBroadBandAdd(params);
  },
  // 详情
  async getBroadBandLoad({ commit, state }, params) {
    return await api.postBroadBandLoad(params);
  },
  // 删除
  async getBroadBandDelete({ commit, state }, params) {
    return await api.postBroadBandDelete(params);
  },
  // 修改
  async getBroadBandEdit({ commit, state }, params) {
    return await api.postBroadBandEdit(params);
  },
  // 停止分销
  async stopPromote({commit, state},params) {
    return await api.stopPromote(params)
  },
  // 加载分销详情
  async promoteInfo({commit,state},params) {
    return await api.promoteInfo(params)
  },
  // 暂存分销
  async storePromote({commit,state},params) {
    return await api.storePromote(params)
  },
  // 提交分销
  async updatePromote({commit,state},params) {
    return await api.updatePromote(params)
  },
  // 橱窗分销
  async showcasePay({commit,state},params) {
    return await api.showcasePay(params)
  },
  // 查询支付状态
  async paySuccess({commit,state},params) {
    return await api.paySuccess(params)
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.broadBandList = list;
    state.broadBandTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
