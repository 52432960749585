/* eslint-disable no-unused-vars */
const UserStatus = () => import ('@/components/user/UserStatus')
const AppUser = () => import ('@/components/user/AppUser')
const NetUser = () => import ('@/components/user/NetUser')
const ImportUser = () => import ('@/components/user/importUser')
const OpenAccount = () => import ('@/components/user/OpenAccount')
const CardOrder = () => import ('@/components/user/CardOrder')
const ReportStop = () => import ('@/components/user/ReportStop')
const AgainPass = () => import ('@/components/user/AgainPass')
const UserSms = () => import ('@/components/user/UserSms')

export default {
  route:[
    {
      path: "userStatus",
      name: "单人受理",
      component: UserStatus
    },
    {
      path: "userSms",
      name: "短信发送",
      component: UserSms
    },
    {
      path: "appUser",
      name: "校园达人",
      component: AppUser
    },
    {
      path: "netUser",
      name: "用户查询",
      component: NetUser
    },
    {
      path: "import",
      name: "批量导入",
      component: ImportUser
    },
    {
      path: "openAccount",
      name: "批量开户",
      component: OpenAccount
    },
    {
      path: "cardOrder",
      name: "批量融合",
      component: CardOrder
    },
    {
      path: "reportStop",
      name: "批量报停",
      component: ReportStop
    },
    {
      path: "againPass",
      name: "批量复通",
      component: AgainPass
    },
  ]
};
