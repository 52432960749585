/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/service/netConfig";

const state = {
  netConfigList:[],
  netConfigTotal: 0,
};

// actions
const actions = {
  async netAdminSchoolNetList({ commit, state }, params) {
    let result = await api.netAdminSchoolNetList(params);
    commit("saveNetList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async netAdminSchoolNetAll({ commit, state }, params) {
    return await api.netAdminSchoolNetAll(params);
  },
  async adminSchoolNetAdd({ commit, state }, params) {
    return await api.adminSchoolNetAdd(params);
  },
  async adminSchoolNetLoad({ commit, state }, params) {
    return await api.adminSchoolNetLoad(params);
  },
  async adminSchoolNetEdit({ commit, state }, params) {
    return await api.adminSchoolNetEdit(params);
  },
  async adminSchoolNetDelete({ commit, state }, params) {
    return await api.adminSchoolNetDelete(params);
  },
  async membersSchoolSelectAll({ commit, state }, params) {
    return await api.membersSchoolSelectAll(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveNetList(state, { list, total }) {
    state.netConfigList = list;
    state.netConfigTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
