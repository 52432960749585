/* eslint-disable no-unused-vars */
const BroadBandOrder = () => import ('@/components/orderManagement/BroadBandOrder')
const DealOrder = () => import ('@/components/orderManagement/DealOrder')
const FuseOrder = () => import ('@/components/orderManagement/FuseOrder')
const Apply = () => import ('@/components/orderManagement/Apply')


export default {
  route:[
    {
      path: "dealOrder",
      name: "交易订单",
      component: DealOrder
    },
    {
      path: "fuseOrder",
      name: "号卡订单",
      component: FuseOrder
    },
    {
      path: "broadBandOrder",
      name: "宽带订单",
      component: BroadBandOrder
    },
    {
      path: "apply",
      name: "单款预约",
      component: Apply
    }
  ]
};
