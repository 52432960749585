/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/common/options";

// actions
const actions = {
  async getSchoolCombo({ commit, state }, params) {
    return await api.getSchoolCombo(params);
  },
  async contestMatchSelectAll({ commit, state }, params) {
    return await api.contestMatchSelectAll(params);
  },
  async contestBannerAll({ commit, state }, params) {
    return await api.contestBannerAll(params);
  },
  async getSchoolTree({ commit, state }, params) {
    return await api.getSchoolTree(params);
  },
  async getAppRoleUsers({ commit, state }, params) {
    return await api.getAppRoleUsers(params);
  },
  async commonDictGetDictFPid({ commit, state }, params) {
    return await api.commonDictGetDictFPid(params);
  },
  async dbMobiles({ commit, state }, params) {
    return await api.dbMobiles(params);
  },
};

export default {
  namespaced: true,
  actions,
};
