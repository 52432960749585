/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询 */
export const batchFuseList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/batchFuse/list",
    method: "post",
    data: params
  });
};

export default {
  batchFuseList,
};
