/*
 * @Auhtor: 文锋
 * @Date: 2021-05-21 11:22:19
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-05-21 11:22:19
 * @FilePath: \net\src\common\utils.js
 */
/* eslint-disable no-unused-vars */
import {
  ACCESS_TOKEN_KEY,
  USER_NAME,
  IS_TEMP_LOGIN,
} from "./constants";
export const log = window.location.href.includes('localhost') ? console.log : () => {};

export const mergeObject = function(a, b) {
  for (let k in a) {
    a[k] = b[k] == null || b[k] == undefined ? a[k] : b[k];
  }
  return a;
};

export const getAccessToken = function() {
  return sessionStorage.getItem(IS_TEMP_LOGIN)
    ? sessionStorage.getItem(ACCESS_TOKEN_KEY)
    : localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const removeToken = function() {
  sessionStorage.removeItem(IS_TEMP_LOGIN);
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(USER_NAME);
};

export const saveAccount = function(accountInfo) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accountInfo.token);
  localStorage.setItem(USER_NAME, accountInfo.userName);
  sessionStorage.setItem(IS_TEMP_LOGIN, 'true');
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accountInfo.token);
  sessionStorage.setItem(USER_NAME, accountInfo.userName);
};

export const isRepeat = function(arr) {
  let setArr = Array.from(new Set(arr))
  return setArr.length == arr.length ? false : true;
}

export const prefixPad = function(num, n, pad = "0") {
  var len = num.toString().length;
  while (len < n) {
    num = pad + num;
    len++;
  }
  return num;
};

export const dateFormat = function(date, fmt) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

export default {
  log,
  mergeObject,
  isRepeat,
  prefixPad,
  dateFormat,
  getAccessToken,
  removeToken,
  saveAccount,
};
