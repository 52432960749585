/* eslint-disable no-unused-vars */
const StoreInfo = () => import ('@/components/store/StoreInfo')
const StoreTreatyManage = () => import ('@/components/store/TreatyManage')
const StoreNotice = () => import ('@/components/store/Notice')
const StorePay = () => import ('@/components/store/storePay')
const CashPage = () => import ('@/components/store/CashPage')

export default {
  route:[
    {
      path: "storeInfo",
      name: "店铺信息",
      component: StoreInfo
    },
    {
      path: "storeTreatyManage",
      name: "协议管理",
      component: StoreTreatyManage
    },
    {
      path: "storeNotice",
      name: "店铺公告",
      component: StoreNotice
    },
    {
      path: "storePay",
      name: "支付与认证",
      component: StorePay
    },
    {
      path: "cashPage",
      name: "提现页面",
      component: CashPage
    },
  ]
};
