/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const postBroadBand = function(params) {
  return axios({
    url: BaseUrl + "net/admin/netMeal/list",
    method: "post",
    data: params
  });
};
 // 所有学校
 export const postSchoolAll = function(params) {
  return axios({
    url: BaseUrl + "/settle/shop/getSchoolByShopId",
    method: "post",
    data: params
  });
};
 // 所有校区
 export const postSchoolDicts = function(params) {
  return axios({
    url: BaseUrl + "members/school/getUserSchoolDicts",
    method: "post",
    data: params
  });
};
 // 条件查找
export const postBroadBandSelect = function(params) {
  return axios({
    url: BaseUrl + "net/admin/netMeal/selectAll",
    method: "post",
    data: params
  });
};
 // 批量上架/下架
 export const postBatchStatusChange = function(params) {
  return axios({
    url: BaseUrl + "net/admin/netMeal/batchStatusChange",
    method: "post",
    data: params
  });
};

// 详情
export const postBroadBandLoad = function(params) {
    return axios({
      url: BaseUrl + "net/admin/netMeal/load",
      method: "post",
      data: params
    });
  };
// 添加
export const postBroadBandAdd = function(params) {
    return axios({
      url: BaseUrl + "net/admin/netMeal/add",
      method: "post",
      data: params
    });
  };
// 修改
export const postBroadBandEdit = function(params) {
    return axios({
      url: BaseUrl + "net/admin/netMeal/edit",
      method: "post",
      data: params
    });
  };
// 删除
export const postBroadBandDelete = function(params) {
    return axios({
      url: BaseUrl + "net/admin/netMeal/delete",
      method: "post",
      data: params
    });
  };



// 分销接口

// 停止分销
export const stopPromote = function (params) {
  return axios({
    url: BaseUrl + 'net/admin/netMeal/stopSpread',
    method: 'post',
    data: params
  })
}

// 加载分销详情
export const promoteInfo = function (params) {
  return axios({
    url:  BaseUrl + 'net/admin/mealSpread/loadByGId',
    method: 'post',
    data: params
  })
}

// 暂存分销
export const storePromote = function (params) {
  return axios ({
    url: BaseUrl + 'net/admin/netMeal/store',
    method: 'post',
    data: params
  })
}

// 提交分销
export const updatePromote = function (params) {
  return axios({
    url:  BaseUrl + 'net/admin/netMeal/spread',
    method: 'post',
    data: params
  })
}

// 橱窗支付
export const showcasePay = function (params) {
  return axios({
    url: BaseUrl + 'net/admin/act/order/add',
    method: 'post',
    data: params
  })
}

// 查询支付状态
export const paySuccess = function (params) {
  return axios({
      url: BaseUrl + 'pay/payTran/check',
      method:'post',
      data: params
  })
}

export default {
  postBroadBand,
  postSchoolAll,
  postSchoolDicts,
  postBroadBandSelect,
  postBatchStatusChange,
  postBroadBandLoad,
  postBroadBandAdd,
  postBroadBandEdit,
  postBroadBandDelete,
  stopPromote,
  promoteInfo,
  storePromote,
  updatePromote,
  showcasePay,
  paySuccess
};