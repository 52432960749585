/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const bizHallList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/bizHall/list",
    method: "post",
    data: params
  });
};

export const bizHallAdd = function(params) {
  return axios({
    url: BaseUrl + "net/admin/bizHall/add",
    method: "post",
    data: params
  });
};

export const bizHallEdit= function(params) {
  return axios({
    url: BaseUrl + "net/admin/bizHall/edit",
    method: "post",
    data: params
  });
};

export const bizHallDelete = function(params) {
  return axios({
    url: BaseUrl + "net/admin/bizHall/delete",
    method: "post",
    data: params
  });
};

export default {
  bizHallList,
  bizHallAdd,
  bizHallEdit,
  bizHallDelete,
};