/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/user/userStatus";

const state = {
  storeList: [],
  storeTotal: 0,
};

// actions
const actions = {
  async userNetList({ commit, state }, params) {
    let result = await api.userNetList(params);
    commit("saveDeptList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async userNetAdd({ commit, state }, params) {
    return await api.userNetAdd(params);
  },
  async userNetLoad({ commit, state }, params) {
    return await api.userNetLoad(params);
  },
  async userNetDelete({ commit, state }, params) {
    return await api.userNetDelete(params);
  },
  async userNetEdit({ commit, state }, params) {
    return await api.userNetEdit(params);
  },
  async userNetResetPwd({ commit, state }, params) {
    return await api.userNetResetPwd(params);
  },
  async userNetResetMpPwd({ commit, state }, params) {
    return await api.userNetResetMpPwd(params);
  },
  async userNetOpen({ commit, state }, params) {
    return await api.userNetOpen(params);
  },
  async userNetMealSelect({ commit, state }, params) {
    return await api.userNetMealSelect(params);
  },
  async userNetRestore({ commit, state }, params) {
    return await api.userNetRestore(params);
  },
  async userNetStop({ commit, state }, params) {
    return await api.userNetStop(params);
  },
  async userNetHistory({ commit, state }, params) {
    return await api.userNetHistory(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveDeptList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
