/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/system/menu";

const state = {
  menuList:[],
  menuTotal: 0,
};

// actions
const actions = {
  async commonMenuList({ commit, state }, params) {
    let result = await api.commonMenuList(params);
    commit("saveMenuList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async commonMenuAdd({ commit, state }, params) {
    return await api.commonMenuAdd(params);
  },
  async commonMenuDelete({ commit, state }, params) {
    return await api.commonMenuDelete(params);
  },
  async commonMenuEdit({ commit, state }, params) {
    return await api.commonMenuEdit(params);
  },
  async getUserPermission({ commit, state }, params) {
    return await api.getUserPermission(params);
  },
  async commonMenuGetUserMenuTree({ commit, state }, params) {
    return await api.commonMenuGetUserMenuTree(params);
  },
  async commonMenuGetAllMenuTree({ commit, state }, params) {
    let list = await api.commonMenuGetAllMenuTree(params);
    commit("saveMenuList", {
      list: list,
    });
    return list;
  },
};

//getters
const getters = {};

const mutations = {
  saveMenuList(state, { list, total }) {
    state.menuList = list;
    state.menuTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
