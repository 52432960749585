/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/store/cash";

const state = {
  incomeList:[],
  incomeTotal: 0,
};

// actions
const actions = {
  async netAdminCashGetCashList({ commit, state }, params) {
    let result = await api.netAdminCashGetCashList(params);
    commit("saveDeptList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async netAdminCashGetDisburseList({ commit, state }, params) {
    return await api.netAdminCashGetDisburseList(params);
  },
  async netAdminCashGetReport({ commit, state }, params) {
    return await api.netAdminCashGetReport(params);
  },
  async netAdminCashGetRangeMoney({ commit, state }, params) {
    return await api.netAdminCashGetRangeMoney(params);
  },
  async shopWithdrawFindLastWithDraw({ commit, state }, params) {
    return await api.shopWithdrawFindLastWithDraw(params);
  },
  async shopWithdrawGetPageList({ commit, state }, params) {
    return await api.shopWithdrawGetPageList(params);
  },
  async shopWithdrawAdd({ commit, state }, params) {
    return await api.shopWithdrawAdd(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveDeptList(state, { list, total }) {
    state.incomeList = list;
    state.incomeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
