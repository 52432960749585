/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const comReportGetList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/com/report/getList",
    method: "post",
    data: params
  });
};

export default {
  comReportGetList,
};