/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询 */
export const ImportLogList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/importLog/list",
    method: "post",
    data: params
  });
};

export default {
  ImportLogList,
};
