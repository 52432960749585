//全局mutation
export default {
  setError(state, error) {
    state.error = error;
  },
  setOA(state, OA) {
    state.OA = OA;
  },
  setProvince(state, province) {
    state.provinceOptions = province;
  },
  savePageParams(state, { pageName, params, useSessionStorage }) {
    state.pageParams[pageName] = params;
    if (useSessionStorage) {
      sessionStorage.setItem("pageParams-" + pageName, JSON.stringify(params));
    }
  },
  setTabsList(state, tabsList) {
    state.tabsList = tabsList;
  },
  setCurrentTab(state, { context, title, fullPath }) {
    // console.log("title:" + title + " fullPath:" + fullPath);
    let tabs = state.tabsList;
    tabs.forEach((tab) => {
      let path = tab.fullPath.split("?")[0];
      if (path === context.$route.path) {
        if (title) {
          tab.name = title;
          state.curBreadcrumbName = title;
        } else {
          state.curBreadcrumbName = tab.name;
        }
        if (fullPath) {
          tab.fullPath = fullPath;
          state.currentTabPath = fullPath;
        } else {
          state.currentTabPath = tab.fullPath;
        }
        tab.path = tab.fullPath.split("?")[0];
      }
    });
    state.tabsList = tabs;
  },
  switchToTab(state, { context, tabIndex }) {
    if (state.tabsList[tabIndex].fullPath == context.$route.fullPath) {
      return;
    }
    state.currentTabPath = state.tabsList[tabIndex].fullPath;
    context.$router.push(state.currentTabPath);
    state.curBreadcrumbName = state.tabsList[tabIndex].name;
  },
  closeCurrentTab(state, { context }) {
    let tabs = state.tabsList;
    let index = tabs.findIndex((item) => {
      return item.fullPath === context.$route.path;
    });

    if (index !== -1) {
      state.tabsList.splice(index, 1);
    }
    context.$router.back();
  },
  setSidebarCollpased(state, isSidebarCollapsed) {
    state.isSidebarCollapsed = isSidebarCollapsed;
  }
};
