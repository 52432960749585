/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/financialList/broadBandList";

const state = {
  broadBandList:[],
  broadBandTotal: 0,
};

// actions
const actions = {
 // 列表
  async broadBandListGetList({ commit, state }, params) {
    let res = await api.broadBandListGetList(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.broadBandList = list;
    state.broadBandTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
