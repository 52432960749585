/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const storeInfoLoad = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/load",
    method: "post",
    data: params
  });
};

// export const commonDeptAdd = function(params) {
//   return axios({
//     url: BaseUrl + "common/dept/add",
//     method: "post",
//     data: params
//   });
// };

// export const commonDeptDelete = function(params) {
//   return axios({
//     url: BaseUrl + "common/dept/delete",
//     method: "post",
//     data: params
//   });
// };

export const storeInfoEdit = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/hallEdit",
    method: "post",
    data: params
  });
};

// 获取已维护学校的省市区树
export const getSchoolAreaTree = function(params) {
  return axios({
    url: BaseUrl + "members/school/getSchoolAreaTree",
    method: "post",
    data: params
  });
};

export default {
  // commonDeptist,
  // commonDeptAdd,
  // commonDeptDelete,
  storeInfoLoad,
  storeInfoEdit,
  getSchoolAreaTree,
};
