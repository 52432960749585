/* eslint-disable no-unused-vars */
const BroadBand = () => import ('@/components/goodsManagement/BroadBand')
const Card = () => import ('@/components/goodsManagement/Card')
const GoodsLabel = () => import ('@/components/goodsManagement/GoodsLabel')
const Number = () => import ('@/components/goodsManagement/Number')
const PhoneImport = () => import ('@/components/goodsManagement/PhoneImport')
const AliPay = () => import ('@/components/goodsManagement/aliPay')

export default {
  route:[
    {
      path: "broadBand",
      name: "宽带套餐",
      component: BroadBand
    },
    {
      path: "card",
      name: "号卡套餐",
      component: Card
    },
    {
      path: "number",
      name: "号码管理",
      component: Number
    },
    {
      path: "phoneImport",
      name: "号码记录",
      component: PhoneImport
    },
    {
      path: "goodsLabel",
      name: "套餐标签",
      component: GoodsLabel
    },
    {
      path: "aliPay",
      name: "支付宝支付",
      component: AliPay
    }
  ]
};
