/* eslint-disable no-unused-vars */
const BroadbandService = () => import ('@/components/network/BroadbandService')
const RealService = () => import ('@/components/network/RealService')

export default {
  route:[
    {
      path: "broadbandService",
      name: "宽带报修",
      component: BroadbandService
    },
    {
      path: "realService",
      name: "物业报修",
      component: RealService
    },
  ]
};
