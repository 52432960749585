/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询 */
export const membersAppGetTargetUserList = function(params) {
  return axios({
    url: BaseUrl + "members/app/getTargetUser",
    method: "post",
    data: params
  });
};

/** 分页查询 */
export const membersAppGrantTalent = function(params) {
  return axios({
    url: BaseUrl + "members/app/grantTalent",
    method: "post",
    data: params
  });
};

export default {
  membersAppGetTargetUserList,
  membersAppGrantTalent
};
