/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

/** 分页查询 */
export const batchStopList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/batchStop/list",
    method: "post",
    data: params
  });
};

export default {
  batchStopList,
};
