/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/goodsManagement/card";

const state = {
  cardList:[],
  cardTotal: 0,
};

// actions
const actions = {
 // 列表
  async getCardList({ commit, state }, params) {
    let res = await api.postCardList(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
 // 批量上架、下架
 async getBatchStatusChange({ commit, state }, params) {
  return await api.postBatchStatusChange(params);
},

  // 获取所有学校
  async getSchoolAll({ commit, state }, params) {
    return await api.postSchoolAll(params);
  },
  // 获取所有校区
  async getSchoolDicts({ commit, state }, params) {
    return await api.postSchoolDicts(params);
  },
  // 添加
  async getCardAdd({ commit, state }, params) {
    return await api.postCardAdd(params);
  },
  // 详情
  async getCardLoad({ commit, state }, params) {
    return await api.postCardLoad(params);
  },
  // 删除
  async getCardDelete({ commit, state }, params) {
    return await api.postCardDelete(params);
  },
  // 修改
  async getCardEdit({ commit, state }, params) {
    return await api.postCardEdit(params);
  },
  // 宽带套餐
  async goodsSelectAll({ commit, state }, params) {
    return await api.goodsSelectAll(params);
  },
  // 添加套餐分销
  async addShopPromotion({commit,state},params) {
    return await api.addShopPromotion(params)
  }
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.cardList = list;
    state.cardTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
