/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const bCustomerList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/Customer/list",
    method: "post",
    data: params
  });
};

export const bCustomerAdd = function(params) {
  return axios({
    url: BaseUrl + "net/admin/Customer/add",
    method: "post",
    data: params
  });
};

export const bCustomerEdit= function(params) {
  return axios({
    url: BaseUrl + "net/admin/Customer/edit",
    method: "post",
    data: params
  });
};

export const bCustomerDelete = function(params) {
  return axios({
    url: BaseUrl + "net/admin/Customer/delete",
    method: "post",
    data: params
  });
};

export default {
  bCustomerList,
  bCustomerAdd,
  bCustomerEdit,
  bCustomerDelete,
};