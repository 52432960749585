/* eslint-disable no-unused-vars */
const ServiceHall = () => import ('@/components/service/ServiceHall')
const ServiceCustomer = () => import ('@/components/service/ServiceCustomer')
const NetConfig = () => import ('@/components/service/NetConfig')

export default {
  route:[
    {
      path: "serviceHall",
      name: "营业厅管理",
      component: ServiceHall
    },
    {
      path: "serviceCustomer",
      name: "客服管理",
      component: ServiceCustomer
    },
    {
      path: "netConfig",
      name: "网络配置",
      component: NetConfig
    },
  ]
};
