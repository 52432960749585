/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const repairList = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/list",
    method: "post",
    data: params
  });
};

export const repairEdit = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/edit",
    method: "post",
    data: params
  });
};

export const repairLoad = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/load",
    method: "post",
    data: params
  });
};

export const repairDelete = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/delete",
    method: "post",
    data: params
  });
};
// 指派
export const repairAssign = function(params) {
  return axios({
    url: BaseUrl + "operate/repair/assign",
    method: "post",
    data: params
  });
};
// 所指派的人员名单
export const getAppRoleUsers = function(params) {
  return axios({
    url: BaseUrl + "members/app/getAppRoleUsers",
    method: "get",
    params: params
  });
};



export default {
  repairList,
  repairEdit,
  repairLoad,
  repairDelete,
  repairAssign,
  getAppRoleUsers
};
