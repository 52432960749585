/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/goodsManagement/goodsLable";

const state = {
  goodsLableList:[],
  goodsLableTotal: 0,
};

// actions
const actions = {
 // 列表
  async getGoodsLableList({ commit, state }, params) {
    let res = await api.postGoodsLableList(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
 
  // 下拉列表
  async getGoodsLableAll({ commit, state }, params) {
    return await api.postGoodsLableAll(params);
  },
  // 添加
  async getGoodsLableAdd({ commit, state }, params) {
    return await api.postGoodsLableAdd(params);
  },
  // 删除
  async getGoodsLableDelete({ commit, state }, params) {
    return await api.postGoodsLableDelete(params);
  },
  // 修改
  async getGoodsLableEdit({ commit, state }, params) {
    return await api.postGoodsLableEdit(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.goodsLableList = list;
    state.goodsLableTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
