/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import { concat } from "lodash";
import { getAccessToken } from "@/common/utils";

import Home from "@/views/Home.vue";
import Page404 from "@/views/Page404.vue";
import Login from "@/components/account/Login.vue";

import views from "./views";
import system from "./system";
import basic from "./basic";
import network from "./network";
import financialList from "./financialList";
import goodsManagement from "./goodsManagement";
import orderManagement from "./orderManagement";
import user from "./user";
import service from "./service";
import store from "./store";

const modules = [
  views,
  system,
  basic,
  network,
  financialList,
  goodsManagement,
  orderManagement,
  user,
  store,
  service
];

let config = {
  // mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      // redirect: "/storeInfo",
      redirect: "/userStatus",
      children:[]
    },
    { path: "/login", name: "登录", component: Login },
  ]
};
modules.forEach(function(m) {
  config.routes[0].children = concat(config.routes[0].children, m.route);
});

config.routes.push({
  // 会匹配所有路径
  path: "*",
  name: "404",
  component: Page404
});

const router = new Router(config);

router.beforeEach((to, from, next) => {
  if (to.path != '/login' && !getAccessToken()) {
    next({
      path: "/login",
    });
  } else {
    next();
  }
})

export default router;
