/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/service/serviceCustomer";

const state = {
  bServiceList:[],
  bServiceTotal: 0,
};

// actions
const actions = {
 // 列表
  async bCustomerList({ commit, state }, params) {
    let res = await api.bCustomerList(params);
    commit("saveHallList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  async bCustomerAdd({ commit, state }, params) {
    return await api.bCustomerAdd(params);
  },
  async bCustomerEdit({ commit, state }, params) {
    return await api.bCustomerEdit(params);
  },
  async bCustomerDelete({ commit, state }, params) {
    return await api.bCustomerDelete(params);
  },
 
};

//getters
const getters = {};

const mutations = {
  saveHallList(state, { list, total }) {
    state.bServiceList = list;
    state.bServiceTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
