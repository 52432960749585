import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


//acccount
import account from "./modules/account/account";
import broadBand from "./modules/goodsManagement/broadBand";
import number from "./modules/goodsManagement/number";
import goodsLable from "./modules/goodsManagement/goodsLable";
import phoneImport from "./modules/goodsManagement/phoneImport";
import card from "./modules/goodsManagement/card";
import fuseOrder from "./modules/orderManagement/fuseOrder";
import apply from "./modules/orderManagement/apply";
import dealOrder from "./modules/orderManagement/dealOrder";
import systemDict from "./modules/system/dict";
import systemMenu from "./modules/system/menu";
import systemRole from "./modules/system/role";
import systemUser from "./modules/system/user";
import systemDept from "./modules/system/dept";
import broadbandService from "./modules/network/broadbandService";
import realService from "./modules/network/realService";
import systemSchool from "./modules/system/school";


import serviceHall from "./modules/service/serviceHall";
import serviceCustomer from "./modules/service/serviceCustomer";
import netConfig from "./modules/service/netConfig";

import options from "./modules/common/options";


import commonOptions from "./modules/common/options";

// 用户管理
import importUser from "./modules/user/importUser";
import userStatus from "./modules/user/userStatus";
import openAccount from "./modules/user/openAccount";
import cardOrder from "./modules/user/cardOrder";
import reportStop from "./modules/user/reportStop";
import againPass from "./modules/user/againPass";
import appUser from "./modules/user/appUser";
import netUser from "./modules/user/netUser";

// 店铺管理
import storeInfo from "./modules/store/info.js";
import storeCash from "./modules/store/cash.js";

// 财务报表
import broadBandList from "./modules/financialList/broadBandList";
import correspond from "./modules/financialList/correspond";
import bydk from "./modules/financialList/bydk";
import byrh from "./modules/financialList/byrh";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
    currentPath: null,
    OA:[],
    pageParams: {},
    tabsList: [
      {
        name: "首页",
        path: "/dashboard",
        fullPath: "/dashboard",
      }
    ],
    currentTabPath: "/dashboard",
    curBreadcrumbName: "首页",
    isSidebarCollapsed: false,

    provinceOptions:[],
  },
  modules: {
    account,
    systemDict,
    systemDict,
    systemMenu,
    systemRole,
    systemUser,
    systemDept, 
    commonOptions,
    broadbandService,
    phoneImport,
    realService,
    systemSchool,
    options,
    broadBand,
    number,
    goodsLable,
    card,
    userStatus,
    fuseOrder,
    apply,
    dealOrder,
    openAccount,
    cardOrder,
    reportStop,
    againPass,
    serviceHall,
    serviceCustomer,
    netConfig,
    storeInfo,
    broadBandList,
    bydk,
    byrh,
    appUser,
    netUser,
    correspond,
    storeCash,
    importUser,
  },
  getters,
  mutations,
  actions
});
