/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/system/user";

const state = {
  userList:[],
  userTotal: 0,
};

// actions
const actions = {
  async membersSysList({ commit, state }, params) {
    let result = await api.membersSysList(params);
    commit("saveUserList", {
      list: result.list,
      total: result.total,
    });
    return result;
  },
  async membersSysAdd({ commit, state }, params) {
    return await api.membersSysAdd(params);
  },
  async membersSysDelete({ commit, state }, params) {
    return await api.membersSysDelete(params);
  },
  async membersSysEdit({ commit, state }, params) {
    return await api.membersSysEdit(params);
  },
  async membersSysGetAreaManager({ commit, state }, params) {
    return await api.membersSysGetAreaManager(params);
  },
  async shopGetSchoolByScopeType({ commit, state }, params) {
    return await api.shopGetSchoolByScopeType(params);
  },
  async membersSchoolSelectAll({ commit, state }, params) {
    return await api.membersSchoolSelectAll(params);
  },
  async shopGetShopSelect({ commit, state }, params) {
    return await api.shopGetShopSelect(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveUserList(state, { list, total }) {
    state.userList = list;
    state.userTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
