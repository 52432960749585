/* eslint-disable no-unused-vars */

const Reload = () => import ('@/views/Reload')

export default {
  route:[
    {
      path: "reload",
      name: "reload",
      component: Reload
    }
  ]
};
