/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/orderManagement/dealOrder";

const state = {
  dealOrderList:[],
  dealOrderTotal: 0,
};

// actions
const actions = {
 // 列表
  async getDealOrderList({ commit, state }, params) {
    let res = await api.postDealOrder(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  async payAlipayRefund({ commit, state }, params) {
    return await api.payAlipayRefund(params);
  },
  async payWxpayRefund({ commit, state }, params) {
    return await api.payWxpayRefund(params);
  },
  async payWxpayRefundMp({ commit, state }, params) {
    return await api.payWxpayRefundMp(params);
  },
  async payYepayRefund({ commit, state }, params) {
    return await api.payYepayRefund(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.dealOrderList = list;
    state.dealOrderTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
