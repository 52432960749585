/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const login = function(params) {
  return axios({
    url: BaseUrl + "members/net/login",
    method: "post",
    data: params
  });
};

export const membersUserPassword = function(params) {
  return axios({
    url: BaseUrl + "members/user/passwd",
    method: "post",
    data: params
  });
};

export const getUserInfo = function(params) {
  return axios({
    url: BaseUrl + "members/net/getUserInfo",
    method: "post",
    data: params
  });
};

export default {
  login,
  membersUserPassword,
  getUserInfo
};
