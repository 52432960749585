/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/orderManagement/apply";

const state = {
  applyList:[],
  applyTotal: 0,
};

// actions
const actions = {
 // 列表
  async getApplyList({ commit, state }, params) {
    let res = await api.listApply(params);
    commit("saveApplyList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  // 编辑
  async getApplyEdit({ commit, state }, params) {
    return await api.editApply(params);
  },
  
};


//getters
const getters = {};

const mutations = {
  saveApplyList(state, { list, total }) {
    state.applyList = list;
    state.applyTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
