/* eslint-disable no-unused-vars */
const BroadBandList = () => import ('@/components/financialList/BroadBandList')
const CardList = () => import ('@/components/financialList/CardList')
const Correspond = () => import ('@/components/financialList/Correspond')
const Bydk = () => import ('@/components/financialList/Bydk')
const Byrh = () => import ('@/components/financialList/Byrh')

export default {
  route:[
    {
      path: "broadBandList",
      name: "单宽报表",
      component: BroadBandList
    },
    {
      path: "cardList",
      name: "融合报表",
      component: CardList
    },
    {
      path: "correspond",
      name: "通信报表",
      component: Correspond
    },
    {
      path: "bydk",
      name: "蚌院单宽",
      component: Bydk
    },
    {
      path: "byrh",
      name: "蚌院融合",
      component: Byrh
    },
  ]
};
