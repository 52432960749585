/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

// 提现明细
export const netAdminCashGetCashList = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cash/getCashList",
    method: "post",
    data: params
  });
};

// 收入明细
export const netAdminCashGetDisburseList = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/getPageList",
    method: "post",
    data: params
  });
};

// 月收入分析
export const netAdminCashGetReport = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cash/getReport",
    method: "post",
    data: params
  });
};

// 指定日期范围内的可提现金额
export const netAdminCashGetRangeMoney = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cash/getRangeMoney",
    method: "post",
    data: params
  });
};

// 获取上一次的提现记录
export const shopWithdrawFindLastWithDraw = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/findLastWithDraw",
    method: "post",
    data: params
  });
};

// 提现明细
export const shopWithdrawGetPageList = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/getPageList",
    method: "post",
    data: params
  });
};

// 提现明细
export const shopWithdrawAdd = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/withdraw/add",
    method: "post",
    data: params
  });
};

export default {
  netAdminCashGetCashList,
  netAdminCashGetDisburseList,
  netAdminCashGetReport,
  netAdminCashGetRangeMoney,
  shopWithdrawFindLastWithDraw,
  shopWithdrawGetPageList,
  shopWithdrawAdd,
};
