/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";
// 列表
export const postNumber = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardInfo/list",
    method: "post",
    data: params
  });
};

// 编辑
export const adminCardInfoEdit = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardInfo/edit",
    method: "post",
    data: params
  });
};

// 删除
export const adminCardInfoDelete = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardInfo/delete",
    method: "post",
    data: params
  });
};

 // 批量导入
export const postNumberImport = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardInfo/import",
    method: "post",
    data: params
  });
};

 // 号码条数
 export const numberGetNumbers = function(params) {
  return axios({
    url: BaseUrl + "net/admin/cardInfo/getNumbers",
    method: "post",
    data: params
  });
};

export default {
  postNumber,
  postNumberImport,
  adminCardInfoEdit,
  adminCardInfoDelete,
  numberGetNumbers
};