/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/user/appUser";

const state = {
  storeList: [],
  storeTotal: 0,
};

// actions
const actions = {
  async membersAppGetTargetUserList({ commit, state }, params) {
    let result = await api.membersAppGetTargetUserList(params);
    commit("saveUserList", {
      list: result.list,
      total: result.total,
    });
    console.log(state.storeList)
    return result;
  },
  async membersAppGrantTalent({ commit, state }, params) {
    return await api.membersAppGrantTalent(params);
  },
};

//getters
const getters = {};

const mutations = {
  saveUserList(state, { list, total }) {
    state.storeList = list;
    state.storeTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
