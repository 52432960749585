/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const membersSchoolList = function(params) {
  return axios({
    url: BaseUrl + "members/school/list",
    method: "post",
    data: params
  });
};

export const membersSchoolLoad = function(params) {
  return axios({
    url: BaseUrl + "members/school/load",
    method: "post",
    data: params
  });
};

export const membersSchoolAdd = function(params) {
  return axios({
    url: BaseUrl + "members/school/add",
    method: "post",
    data: params
  });
};

export const membersSchoolDelete = function(params) {
  return axios({
    url: BaseUrl + "members/school/delete",
    method: "post",
    data: params
  });
};

export const membersSchoolEdit = function(params) {
  return axios({
    url: BaseUrl + "members/school/edit",
    method: "post",
    data: params
  });
};

export default {
  membersSchoolList,
  membersSchoolAdd,
  membersSchoolDelete,
  membersSchoolEdit,
  membersSchoolLoad
};