/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const commonMenuList = function(params) {
  return axios({
    url: BaseUrl + "common/menu/list",
    method: "post",
    data: params
  });
};

export const commonMenuAdd = function(params) {
  return axios({
    url: BaseUrl + "common/menu/add",
    method: "post",
    data: params
  });
};

export const commonMenuDelete = function(params) {
  return axios({
    url: BaseUrl + "common/menu/delete",
    method: "post",
    data: params
  });
};

export const commonMenuEdit = function(params) {
  return axios({
    url: BaseUrl + "common/menu/edit",
    method: "post",
    data: params
  });
};

export const commonMenuGetAllMenuTree = function(params) {
  return axios({
    url: BaseUrl + "common/menu/getAllMenuTree",
    method: "post",
    data: params
  });
};

export const commonMenuGetUserMenuTree = function(params) {
  return axios({
    url: BaseUrl + "common/menu/getUserMenuTree",
    method: "post",
    data: params
  });
};
 
export const getUserPermission = function(params) {
  return axios({
    url: BaseUrl + "common/menu/getUserPermission",
    method: "post",
    data: params
  });
};

export default {
  commonMenuList,
  commonMenuAdd,
  commonMenuDelete,
  commonMenuEdit,
  commonMenuGetAllMenuTree,
  getUserPermission,
  commonMenuGetUserMenuTree
};
