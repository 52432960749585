/* eslint-disable no-unused-vars */
import { BaseUrl } from "@/common/config";
import axios from "axios";

export const membersSysList = function(params) {
  return axios({
    url: BaseUrl + "members/net/list",
    method: "post",
    data: params
  });
};

export const membersSysAdd = function(params) {
  return axios({
    url: BaseUrl + "members/net/add",
    method: "post",
    data: params
  });
};

export const membersSysDelete = function(params) {
  return axios({
    url: BaseUrl + "members/net/delete",
    method: "post",
    data: params
  });
};

export const membersSysEdit = function(params) {
  return axios({
    url: BaseUrl + "members/net/edit",
    method: "post",
    data: params
  });
};

export const membersSysGetAreaManager = function(params) {
  return axios({
    url: BaseUrl + "members/sys/getAreaManager",
    method: "post",
    data: params
  });
};

// 获取学校
export const shopGetSchoolByScopeType = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/getSchoolByScopeType",
    method: "post",
    data: params
  });
};

// 获取校区
export const membersSchoolSelectAll = function(params) {
  return axios({
    url: BaseUrl + "members/schoolDist/selectAll",
    method: "post",
    data: params
  });
};

// 选择店铺
export const shopGetShopSelect = function(params) {
  return axios({
    url: BaseUrl + "settle/shop/getShopSelect",
    method: "post",
    data: params
  });
};

export default {
  membersSysList,
  membersSysAdd,
  membersSysDelete,
  membersSysEdit,
  membersSysGetAreaManager,
  shopGetSchoolByScopeType,
  membersSchoolSelectAll,
  shopGetShopSelect
};
