import Vue from 'vue'
import App from "./App";
import router from "./router";
import store from "./store/index";
import ElementUI from 'element-ui';
import '@/assets/scss/theme/index.css';
import apiCommon from "@/api/common";

import AMap from 'vue-amap'
Vue.use(AMap)

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 申请的高德key
  key: '5d314d065ccc92721a60df351a2f2400',
  // 插件集合
  plugin: ['AMap.Geolocation','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geocoder']
})

Vue.config.productionTip = false
Vue.use(ElementUI);
apiCommon.init();

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
