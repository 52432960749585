/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/service/serviceHall";

const state = {
  bHallList:[],
  bHallTatol: 0,
};

// actions
const actions = {
 // 列表
  async bizHallList({ commit, state }, params) {
    let res = await api.bizHallList(params);
    commit("saveHallList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
  async bizHallAdd({ commit, state }, params) {
    return await api.bizHallAdd(params);
  },
  async bizHallEdit({ commit, state }, params) {
    return await api.bizHallEdit(params);
  },
  async bizHallDelete({ commit, state }, params) {
    return await api.bizHallDelete(params);
  },
 
};

//getters
const getters = {};

const mutations = {
  saveHallList(state, { list, total }) {
    state.bHallList = list;
    state.bHallTatol = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
