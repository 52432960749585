<template>
<!-- 获取侧边栏 -->
  <div class="plat-slider" :class="{ on: isSidebarCollapsed }">
    <div class="plat-menu-box page">
      <div class="plat-menu">
        <el-menu :collapse="isSidebarCollapsed" :default-active="currentTabPath" background-color="#fff">
					<div >
            <div v-for="(item,index) in menus" :key="index">
              <div v-if="item.permission=='network'" class="network">
                <i class="el-icon" :class="item.icon"></i>
                <span class="menu-name" @click="goNetwork">{{item.title}}</span>
              </div>
              <div v-else>
                <el-submenu :index="'menu' + index">
                  <template slot="title">
                    <i class="el-icon" :class="item.icon"></i>
                    <span class="menu-name" >{{item.title}}</span>
                  </template>
                  <el-menu-item @click="toPage('/' + child.path)" :class="{ 'is-active': checkActive('/' + child.path) }" v-for="(child,index) in item.children" :index="child.path" :key="index">
                    <span class="menu-name">{{child.title}}</span>
                  </el-menu-item>
                </el-submenu>
              </div>
            </div>
          </div>
				</el-menu>
      </div>
      <div class="navbar-inner" @click="collapseChange()">
        <i :class="isSidebarCollapsed ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState, mapMutations } from "vuex";
import { log,getAccessToken } from "@/common/utils";
export default {
  name: "SideBar",
  data() {
    return {
      // menus: [],
      
      menus: [
        // { title:'系统管理', icon:'', children:[
        //   // { title:'字典管理', path:'dictManagement' },
        //   { title:'菜单管理', path:'menuManagement' },
        //   // { title:'角色管理', path:'roleManagement' },
        //   // { title:'部门管理', path:'deptManagement' },
        // ]}
      ],
      menuNetwork:[],
      curMenu: "",
    };
  },
  computed: {
    ...mapState(["isSidebarCollapsed", "currentTabPath"])
  },
  methods: {
    ...mapMutations(["setSidebarCollpased"]),
    ...mapActions("systemMenu",[ "commonMenuGetAllMenuTree","commonMenuGetUserMenuTree" ]),
    toPage(path, query = {}) {
      if (!path || this.$route.path == path) return;
      this.$router.push(path);
    },
    collapseChange() {
      this.setSidebarCollpased(!this.isSidebarCollapsed);
    },
    checkActive(path) {
			return this.currentTabPath == path;
    },
    async getMenusData(){
      this.menus = await this.commonMenuGetAllMenuTree({})
    },
    async getMenusData(){
      let saas = sessionStorage.getItem('shopId')
      this.menus = await this.commonMenuGetUserMenuTree({saas})
    },
    goNetwork() {
      let test = window.location.href.includes('test');
      
      let data = '';
      if(test){
        data = 'http://repair.test.ahqmhl.cn/#/fuseOrder?token='+ getAccessToken()
      }else{
        data = 'http://repair.ahqmhl.cn/#/fuseOrder?token='+ getAccessToken()
      }
      window.open(data, '_blank')
    },
  },
  async mounted() {
    await this.getMenusData()
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
$menuW: 180px;
.plat-slider {
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  width: $menuW;
  background: #fff;
  font-size: 14px;
  transition: width 0.3s ease-in-out;
  z-index: 2000; //要比局部loading高，但是比弹出对话框低
  .plat-menu-box {
    .plat-menu {
      position: relative;
      width: $menuW;
      height: 100%;
      overflow-x: hidden;
      .network{ height: 40px;display: flex;align-items: center;color: #fff;padding-left: 0 !important;background-color: #28b892 !important;margin-bottom: 2px;}
			.el-menu { background:#fff; padding-top:2px; }
			.el-submenu__icon-arrow { width: 12px; }
			.el-submenu { margin-bottom:2px; 
				.el-menu-item{ min-width:auto; height:40px; line-height:40px; }
				span { line-height:40px; }
			}
			.el-submenu__title { height: 40px; display: flex; align-items: center; color: #fff; padding-left: 0 !important; background-color:#28b892 !important; }
      .el-icon { display:inline-block; width:40px; color:#fff; text-align:center; line-height:40px; font-size:1.2em; }
      .el-menu-item { margin:2px 0; padding-left:35px !important; background: #fff; border-right:transparent 5px solid; }
			.el-menu-item:hover,.el-menu-item.is-active,.el-menu-item.is-active:hover { background: #dff5ef !important; border-right-color:#28b892; color:#28b892; }
			.el-submenu__title i { color: #fff; }
    }
  }
  &.on { width:64px;
    .el-submenu__title { padding:0 !important; overflow:hidden; justify-content:center;
      .menu-name,.el-icon-arrow-right { display:none !important; }
    }
  }
  .navbar-inner {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 12px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    margin-top: -32px;
    cursor: pointer;
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: block;
      content: "";
      border-bottom: 8px solid transparent;
      border-right: none;
      border-left: 12px solid #ccc;
      border-top: 8px solid transparent;
      transition: all 0.3s ease-in-out;
    }
    i {
      position: relative;
      z-index: 2;
      color: #fff;
      transition: all 0.3s ease-in-out;
    }
    &:hover:before {
      border-left-color: #aaa;
    }
    &:hover i {
      color: #fff;
      text-shadow: 0 0 2px #fff;
    }
  }
}
.el-menu--vertical .el-menu-item { height: 40px; line-height:40px;
  &:hover { background: #dff5ef !important; }
}
</style>
