/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
import api from "@/api/financialList/correspond";

const state = {
  correspondList:[],
  correspondTotal: 0,
};

// actions
const actions = {
 // 列表
  async comReportGetList({ commit, state }, params) {
    let res = await api.comReportGetList(params);
    commit("saveSchoolList", {
      list: res.list,
      total: res.total,
    });
    return res;
  },
};

//getters
const getters = {};

const mutations = {
  saveSchoolList(state, { list, total }) {
    state.correspondList = list;
    state.correspondTotal = total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
